import { Typography } from "@mui/material";
import React from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";

function NotAuthorized() {
  return (
    <BackgroundGradient>
      <Card style={{ alignSelf: "center" }}>
        <Typography sx={{ textAlign: "center" }}>
          You are not authorized to view this page.
          <br />
          Please contact MTS to be added.
        </Typography>
      </Card>
    </BackgroundGradient>
  );
}

export default NotAuthorized;
