import styled from "@emotion/styled";
import { Paper as MUICard } from "@mui/material";

const Card = styled(MUICard)`
  display: flex;
  background: white;
  padding: clamp(0.5em, 4vw, 4em);
  margin: 2em;
`;

export default Card;
