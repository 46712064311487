import { Button, Typography } from "@mui/material";
import { ReactComponent as LogoutIcon } from "../../assets/logoutIcon.svg";
import MSLogo from "../../assets/msLogo.png";
import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";

const { version } = require("../../../package.json");

function Header() {
  const { instance } = useMsal();
  return (
    <StyledHeader>
      <div className="ms-logo-wrapper">
        <img src={MSLogo} className="ms-logo" alt={"Momentum Solar Logo"} />
        <Typography variant="subtitle2" sx={{ marginLeft: "2em" }}>
          v {version}
        </Typography>
      </div>
      <div className="logout-wrapper">
        <p style={{ color: "white", fontSize: "12px", fontWeight: "bold" }}>
          Signed in as {instance.getActiveAccount().name}
        </p>
        <Button
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: "/" })
          }
          variant="contained"
          sx={{
            color: "grey",
            zIndex: 10000,
            background: "white",
          }}
          endIcon={<LogoutIcon />}
        >
          log out
        </Button>
      </div>
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  img {
    height: 3em;
    margin-left: 0.5em;
  }

  p {
    margin: 0;
  }

  .logout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    margin-right: 2em;
  }

  .ms-logo-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1em;
    color: white;
  }

  .ms-logo {
    height: 3em;
  }

  @media (max-width: 780px) {
    .logout-wrapper {
      flex-direction: column-reverse;
      gap: 0px;
      justify-content: center;
      align-items: flex-end;
      margin-right: 1em;
      text-align: end;
    }
  }
`;
