import { Route, Routes, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./pages/Auth/PrivateRoute";

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import PageNotFound from "./pages/Auth/PageNotFound";
import NotAuthorized from "./pages/Auth/NotAuthorized";
import IssueForm from "./pages/IssueForm/IssueForm";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<IssueForm />} />
            </Route>

            <Route path="/not-authorized" element={<NotAuthorized />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </MsalAuthenticationTemplate>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
