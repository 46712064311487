import styled from "@emotion/styled";
import { Add, DeleteOutline } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React from "react";
import { usePostFilesMutation } from "./hooks/useBugReportMutation";

function AddFiles({ files, setFiles }) {
  return (
    <>
      <Typography variant="h6" sx={{ marginTop: "1em" }}>
        Screenshots & Files
      </Typography>
      <Typography variant="">
        You can include any screenshots, videos, or related files here.
      </Typography>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <a
          href="https://support.apple.com/en-us/HT210781"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="caption">
            How to take a screenshot on an iPad
          </Typography>
        </a>
        <a
          href="https://support.apple.com/en-us/HT207935"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="caption">
            How to take a video of your screen on an iPad
          </Typography>
        </a>
        <a
          href="https://reviewed.usatoday.com/laptops/features/how-to-take-a-screenshot-on-mac-and-pc"
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="caption">
            How to take a screenshot of your computer screen
          </Typography>
        </a>
      </div>
      {files.map((file, i) => (
        <FileButton
          key={"file_" + i}
          file={file}
          handleDelete={() =>
            setFiles((prev) => prev.filter((v) => v !== file))
          }
        />
      ))}
      <Button
        variant="outlined"
        component="label"
        startIcon={<Add />}
        sx={{ margin: "auto" }}
      >
        Add Screenshots or Files
        <input
          type="file"
          hidden
          multiple
          onChange={(e) => {
            setFiles((prev) => [...prev, ...Array.from(e.target.files)]);
          }}
        />
      </Button>
    </>
  );
}

const FileButton = ({ file, handleDelete }) => {
  return (
    <Button endIcon={<DeleteOutline />} onClick={handleDelete}>
      {file.name}
    </Button>
  );
};

export default AddFiles;
