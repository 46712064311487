import styled from "@emotion/styled";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";
import AddFiles from "./AddFiles";
import { appOptionSet } from "./optionSets";

import { ReactComponent as BugCatcherIcon } from "../../assets/bugCatcherIcon.svg";
import { useBugReportMutation } from "./hooks/useBugReportMutation";
import { useMsal } from "@azure/msal-react";

function IssueForm() {
  const { username: email, localAccountId } =
    useMsal().instance.getActiveAccount();

  const [searchParams, setSearchParams] = useSearchParams();
  const [files, setFiles] = useState([]);
  const [application, setApplication] = useState();

  const submitFormMutation = useBugReportMutation({});

  useEffect(() => {
    searchParams.set("email", email);
  }, [email]);

  useEffect(() => {
    const appFromParams = appOptionSet.find(
      (option) =>
        option?.label?.toLowerCase()?.replaceAll(" ", "-") ===
        searchParams
          ?.get("application" ?? "")
          ?.toLowerCase()
          ?.replaceAll(" ", "-")
    );
    setApplication(appFromParams);
  }, [searchParams]);

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData(e.target);
    const body = { azureactivedirectoryobjectid: localAccountId, application };

    // ADD CRM ID (localAccountId)
    // console.log(formData);
    for (let entry of formData.entries()) {
      body[entry[0]] = entry[1];
    }
    console.log(body);

    // submitFormMutation.mutate({
    //   files,
    //   formData: body,
    // });
  }

  function _updateSearchParams(key) {
    return (e) =>
      setSearchParams((prev) => {
        prev.set(key, e.target.value);
        return prev;
      });
  }

  return (
    <BackgroundGradient color="#336699">
      <Card sx={{ maxWidth: "75ch" }}>
        <form onSubmit={handleSubmit}>
          <Header>
            <BugCatcherIcon />
            <Typography variant="h4">Momentum Bug Catcher</Typography>
          </Header>
          <InputsWrapper>
            <Typography variant="h5">Employee Contact Information</Typography>
            <Row>
              <SmallTextField
                label="First Name"
                placeholder="John"
                value={searchParams.get("firstName") ?? ""}
                onChange={_updateSearchParams("firstName")}
                name="first_name"
                required
              />
              <SmallTextField
                label="Last Name"
                value={searchParams.get("lastName") ?? ""}
                placeholder="Doe"
                onChange={_updateSearchParams("lastName")}
                name="last_name"
                required
              />
            </Row>
            <TextField
              label="Momentum Email"
              value={searchParams.get("email") ?? ""}
              placeholder="JDoe@momentumsolar.com"
              onChange={_updateSearchParams("email")}
              name="email"
              required
            />
            <Label variant="h5">Issue Details</Label>
            <Typography>
              Please include as much specific information as possible. The more
              information you provide to us, the more equipped we will be to
              track down the issue.
            </Typography>
            <Label variant="h6">Application</Label>
            <Autocomplete
              options={appOptionSet}
              value={application || null}
              onChange={(e, value) => {
                setSearchParams((prev) => {
                  prev.set("application", value.label);
                  return prev;
                });
                setApplication(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Application"
                  required
                  value={application?.label || ""}
                />
              )}
            />
            {application?.label === "Other - Please specify" && (
              <SmallTextField
                label="Which application?"
                placeholder="Other app"
                required={application?.label === "Other - Please specify"}
              />
            )}
            <Label variant="h6">Describe your issue</Label>
            <Typography>
              What is the nature of the issue you encountered?
            </Typography>
            <LargeTextField
              label="Description of issue"
              placeholder=""
              multiline
              name="issue_description"
            />
            <Label variant="h6">
              What specific information do we need to reproduce the issue?
            </Label>
            <Typography>
              What steps in the application did you take before encountering the
              issue (For example: Login > Menu > My Sales Appts > John Doe's
              appointment > Crash!)? If relevant, please also include any
              specific information (customer name, email, opportunity number,
              project number, etc)
            </Typography>
            <LargeTextField
              label="How to reproduce"
              placeholder=""
              multiline
              name="reproduce_description"
            />
            <AddFiles
              files={files}
              setFiles={setFiles}
              email={searchParams.get("email") ?? ""}
            />
            <Label variant="h6">Other notes</Label>
            <Typography>Any other notes or comments</Typography>
            <LargeTextField
              label="Other notes"
              placeholder=""
              multiline
              name="other_notes"
            />

            <Line />
            <Button
              variant="contained"
              type="submit"
              size="large"
              sx={{ margin: "auto" }}
            >
              Submit Bug Report
            </Button>
          </InputsWrapper>
        </form>
      </Card>
    </BackgroundGradient>
  );
}

export default IssueForm;

const InputsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  flex-direction: column;
`;

const SmallTextField = styled(TextField)`
  flex: 1;
  min-width: 32ch;
`;

const LargeTextField = styled(SmallTextField)`
  min-width: 32ch;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 1em;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: lightgrey;
`;

const Label = styled(Typography)`
  margin-top: 1em;
`;
