const baseAppOptionSet = [
  "Sales App",
  "mRelay",
  "Roof Sales App",
  // "Customer App",
  "Proposal Dashboard",
  "Engineering Visit",
];

function formatForMUIAutocomplete(baseOptionSet) {
  const sorted = baseOptionSet.sort();
  sorted.push("Other - Please specify");
  return sorted.map((label) => ({
    label,
  }));
}

export const appOptionSet = formatForMUIAutocomplete(baseAppOptionSet);
