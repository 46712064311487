import { Outlet, Navigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const PrivateRoute = ({ allowedUsers, children }) => {
  const { username } = useMsal().instance.getActiveAccount();

  // If a "allowedUsers" array is provided, authenticate email address
  // If no array is provided, validate auth by presence of a username
  const isAuthorized = allowedUsers?.length
    ? allowedUsers.includes(username)
    : !!username;

  return true ? <Outlet /> : <Navigate to="/not-authorized" />;
};

export default PrivateRoute;
