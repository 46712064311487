import { useMutation } from "@tanstack/react-query";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import dayjs from "dayjs";
import axios from "axios";
import encodeS3URI from "../../../util.js/encodeS3URI";
const accessKeyId = "AKIA2VYLNHYVRZ4L2MP6";
const secretAccessKey = "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";
const Bucket = "mts-bug-reporter";
const region = "us-east-1";

const s3Client = new S3Client({
  region,
  credentials: { accessKeyId, secretAccessKey },
});

async function postFilesToS3(files, email) {
  const folderName = email.split("@")[0].replaceAll(".", "-");
  const promises = files.map(async (file) => {
    // AWS SDK does not return the final path url so we construct it here.
    const fileExtension = "." + file.name.split(".")[1];
    const filename = `${
      file.name.split(".")[0]
    }_${dayjs().unix()}${fileExtension}`;

    const filePath = `${folderName}/${file.name}`;
    const uri = `http://${Bucket}.s3.${region}.amazonaws.com/${filePath}`;
    const url = encodeS3URI(uri);

    try {
      // Dispatch PutObjectCommand to upload the image
      const res = s3Client
        .send(
          new PutObjectCommand({
            Bucket,
            Key: filePath,
            Body: file,
            ContentType: file.type,
          })
        )
        .then((_) => ({
          filename,
          url,
        })) // We add back in the changed filename and new url
        .catch((error) => {
          console.log(error);
        });
      return res;
    } catch (error) {
      console.log(error);
    }
  });
  const res = await Promise.allSettled(promises);

  return res.reduce((finalStips, res) => {
    if (res.status === "fulfilled") finalStips.push(res.value);
    return finalStips;
  }, []);
}

async function postBugReport({ formData, files }) {
  const fileUrls = await postFilesToS3(files, formData.email);

  formData.file_urls = fileUrls;

  try {
    const result = await axios.post("#", formData);
    return result;
  } catch (error) {
    alert(error);
  }
}

export function useBugReportMutation(options) {
  return useMutation(postBugReport, options);
}
