import { Typography } from "@mui/material";
import React from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";

function PageNotFound() {
  return (
    <BackgroundGradient>
      <Card>
        <Typography sx={{ textAlign: "center" }}>
          404: Page not found.
        </Typography>
      </Card>
    </BackgroundGradient>
  );
}

export default PageNotFound;
